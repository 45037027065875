

/* .widthPrefCookies{
    position: sticky !important;
    top: 0px !important;
} */

@media screen and  (max-width: 960px){
    .breakDispCookies{
     display: block!important;
    }
    .widthPrefCookies{
        width: 100%!important;
    }
   .cambioPrefCookies{
        padding-left:0px!important;
        padding-top: 2rem!important;
    } 
    .papelSinPadCook{
        padding: 0!important;
    }

}



